import React from 'react'
/* import { FooterMapa } from "../Footer/FooterMapa" */
import { FooterNew } from '../Footer/FooterNew'
import { PostFooter } from '../PostFooter/PostFooter'

export const HomeFooter = () => {
  return (
    <div>
        {/* <FooterMapa/> */}
        <FooterNew/>
        <PostFooter/>
    </div>
  )
}
