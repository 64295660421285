import React from 'react'
import "./PreNavbarStyle.css"

export const PreNavbar = () => {
  return (
    <div className='preNav'>
        <p className='imagenFont2'>info@vimail.com.ar</p>
        <p className='imagenFont'>Atención telefónica: +54(011)6841-3870</p>
    </div>
  )
}
